.root {
    display: flex;
}

.image {
    width: 200px;
    height: 150px;
}

.items {
    margin-left: var(--spacing-core-4);
}

.item {
    display: flex;
    align-items: baseline;
}

.icon {
    margin-right: var(--spacing-core-2);
}
