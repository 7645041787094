.root {
    margin-top: var(--spacing-core-6);
}

.list {
    list-style-type: decimal;
    padding-left: var(--spacing-core-4);
}

.item {
    margin-top: var(--spacing-core-4);
}

.type {
    text-transform: capitalize;
    padding-right: var(--spacing-core-2);
}
