.root {
    display: none;
}

@media print {
    .sign {
        margin-top: var(--spacing-core-12);
    }
    
    .logo {
        width: 150px;
        height: 50px;
    }
    
    .root {
        display: flex; 
        flex-flow: row wrap;
    }
    
    .title {
        margin-bottom: var(--spacing-core-2);
    }
    
    .column {
        display: inline;
        padding: var(--spacing-core-3);
    }
    
    .full {
        width: 100%;    
    }
    
    .columnRight {
        width: 33%;
    }
    
    .columnLeft {
        width: 66%;
    }

    @page {
        margin-top: var(--spacing-core-8);
        margin-bottom: var(--spacing-core-8);       
    }
}