.root {
    margin-bottom: var(--spacing-core-6);
}

.title {
    margin-bottom: var(--spacing-core-4);
}

.caption {
    font-style: italic;
}

.ingredients {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-core-2);
}