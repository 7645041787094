.ingredients {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-core-4);

    &Button {
        fill: #FFF;
        display: flex;
        align-items: center;
        gap: var(--spacing-core-1);
        padding: var(--spacing-core-3) var(--spacing-core-4);
        border-radius: var(--spacing-core-2);
    }
}
